<template>
  <vue-apex-charts
    type="heatmap"
    height="300"
    :options="config"
    :series="data"
  />
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "lineChart",
  components: {
    VueApexCharts,
  },

  props: {
    data: Array,
  },

  data() {
    return {
      config: {
        plotOptions: {
          heatmap: {
            enableShades: false,
            distributed: true,
            colorScale: {
              ranges: [
                {
                  from: 0,
                  to: 15,
                  color: "#28c76f",
                  foreColor: undefined,
                  name: "",
                },
                {
                  from: 16,
                  to: 30,
                  color: "#00cfe8",
                  foreColor: undefined,
                  name: "",
                },
                {
                  from: 31,
                  to: 45,
                  color: "#17a2b8",
                  foreColor: undefined,
                  name: "",
                },
                {
                  from: 46,
                  to: 60,
                  color: "#ff9f43",
                  foreColor: undefined,
                  name: "",
                },
                {
                  from: 61,
                  to: 200,
                  color: "#ea5455",
                  foreColor: undefined,
                  name: "",
                },
              ],
            },
          },
        },
        legend: {
          show: true,
          position: "bottom",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 600,
          horizontalAlign: "center",
          markers: {
            radius: 12,
          },
          labels: {
            colors: "#A9A9A9",
          },
          itemMargin: {
            horizontal: 10,
            vertical: 20,
          },
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              colors: "#A9A9A9",
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#A9A9A9",
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
        },
      },
    };
  },
};
</script>
