<template>
  <div id="scraping-schedule-page">
    <b-card no-body class="h-100">
      <b-card-header class="pb-1">
        <b-card-title>
          <i class="bi bi-calendar3"></i>
          <span style="margin-left: 5px"> Peak Time </span>
        </b-card-title>
        <div>
          <select
            v-model="filter_data.vm_selected"
            class="form-control"
            @change="getPeaktime()"
            style="width: 10rem"
          >
            <option v-for="option in server_options" :value="option._id">
              {{ option.name }}
            </option>
          </select>
        </div>
      </b-card-header>
      <div>
        <loader v-if="is_peaktime_loading" />
        <div v-else>
          <heatMap :data="peaktime_data" />
        </div>
      </div>
    </b-card>
    <b-card no-body class="h-100">
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-calendar3"></i>
          <span style="margin-left: 5px"> Jadwal Scraping </span>
        </b-card-title>
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <b-button variant="outline-success" @click="exportSchedule">
            <feather-icon icon="FileTextIcon" />
            <span style="margin-left: 2px">Export</span>
          </b-button>
          <!-- hour filter -->
          <b-input-group class="input-group-merge" style="width: 10rem">
            <b-input-group-prepend is-text style="height: 2.7rem">
              <i class="bi bi-hourglass"></i>
            </b-input-group-prepend>
            <b-form-input
              placeholder="Jam"
              v-model="filter_data.hour"
              type="number"
              min="0"
              max="23"
            />
          </b-input-group>
          <!-- minutes filter -->
          <b-input-group class="input-group-merge" style="width: 10rem">
            <b-input-group-prepend is-text style="height: 2.7rem">
              <feather-icon icon="ClockIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Menit"
              v-model="filter_data.minute"
              type="number"
              min="0"
              max="60"
            />
          </b-input-group>
          <!-- keyword filter -->
          <b-input-group class="input-group-merge" style="width: 20rem">
            <b-input-group-prepend is-text style="height: 2.7rem">
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="filter_data.key"
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader v-if="is_loading" />
      <!-- dataTable -->
      <dataTable v-else :data="table_data" :is_pagination="false" />
      <paginationInfo
        :page="pagination.page"
        :items="pagination.items"
        :count="pagination.count"
        @update-value="paginationChanged"
      />
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import paginationInfo from "@/views/components/paginationInfo.vue";
import heatMap from "@/views/components/heatMap.vue";
import loader from "@/views/components/loader.vue";
import {
  BCard,
  BButton,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormTimepicker,
} from "bootstrap-vue";

export default {
  name: "ScrapingSchedule",
  components: {
    BCard,
    BButton,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormTimepicker,
    paginationInfo,
    dataTable,
    heatMap,
    loader,
  },
  data() {
    return {
      filter_data: {
        key: "",
        hour: "",
        minute: "",
        vm_selected: "VM1",
      },
      is_peaktime_loading: true,
      is_loading: true,
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      peaktime_data: [],
      server_options: [],
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "scraper_name",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
            thStyle: { width: "auto" },
          },
          {
            key: "minute",
            label: "menit",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
          {
            key: "hour",
            label: "jam",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
          {
            key: "cron",
            label: "Cron",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
          {
            key: "tag",
            label: "Tags",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
          {
            key: "server",
            label: "server",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "30rem" },
          },
        ],
        items: [],
      },
      cancel_token: null,
    };
  },
  created() {
    this.getServerOptions();
    this.getData();
    this.getPeaktime();
  },
  watch: {
    "filter_data.key"() {
      if (
        this.filter_data.key.trim().length >= 2 ||
        this.filter_data.key.trim().length == 0
      ) {
        this.getData();
      }
    },
    "filter_data.hour"() {
      this.getData();
    },
    "filter_data.minute"() {
      this.getData();
    },
  },
  methods: {
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    getServerOptions() {
      useJwt.axiosIns.get("crontab/server").then((res) => {
        this.server_options = res.data || [];
        this.server_options.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      });
    },
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      const params = {
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
        ...(this.filter_data.hour
          ? { jam: Number(this.filter_data.hour) }
          : {}),
        ...(this.filter_data.minute
          ? { menit: Number(this.filter_data.minute) }
          : {}),
        page: this.pagination.page,
        items: this.pagination.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "crontab/?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res.data?.crontab || [];
          this.pagination.count = res.data?.page_info?.[0]?.total || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    getPeaktime() {
      this.is_peaktime_loading = true;
      const params = {
        server: this.filter_data.vm_selected,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "crontab/peak-time?" + query;
      useJwt.axiosIns
        .get(api)
        .then((res) => {
          if (res.status == 200) {
            this.assemblePeakTime(res.data);
          }
        })
        .finally(() => {
          this.is_peaktime_loading = false;
        });
    },
    assemblePeakTime(data) {
      const temp_data = {};
      data.forEach((item) => {
        const [jam, menit, total] = item;
        let range_menit = 0;
        if (menit < 6) {
          range_menit = 0;
        } else {
          range_menit = Math.floor((menit - 1) / 5) * 5 + 1;
        }

        const name = `${range_menit}-${
          range_menit == 0 ? range_menit + 5 : range_menit + 4
        }`;
        if (!temp_data[name]) {
          temp_data[name] = {
            name: name,
            data: [],
          };
        }
        const existing_data = temp_data[name].data.find(
          (d) => d.x === jam.toString().padStart(2, "0")
        );
        if (existing_data) {
          existing_data.y += total;
        } else {
          temp_data[name].data.push({
            x: jam.toString().padStart(2, "0"),
            y: total,
          });
        }
      });
      this.peaktime_data = Object.values(temp_data);
    },
    exportSchedule() {
      const params = {
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
        ...(this.filter_data.hour
          ? { jam: Number(this.filter_data.hour) }
          : {}),
        ...(this.filter_data.minute
          ? { menit: Number(this.filter_data.minute) }
          : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "crontab/export?" + query;
      window.open(api);
    },
  },
};
</script>
